import React from 'react';
import '../../StylesCSS/ButtonsLayout.css'; 
import { ImportExport, Build, Edit ,Description } from '@mui/icons-material'; // Import des icônes
import { useNavigate } from 'react-router-dom';  // Import de useNavigate

const PageLayout = () => {
    const navigate = useNavigate();  // Initialisation de useNavigate

    const handleSectionChange = (route) => {
        navigate(`/${route}`);  // Navigation vers la route correspondante
    };

    return (
        <div>
            <div style={{ marginTop: '60px', marginBottom: '20px' }}></div>
            <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'right' }}>
                <h2 style={{ textAlign: 'right' }}> : تدبير مجالس الأقســــام  </h2>
                <div className="buttons-container">
                    <div className="button-group">
                        <button 
                            className="btn btn-left" 
                            onClick={() => handleSectionChange('ImportFileExcel')}
                            style={{
                                backgroundColor: '#218C91',
                                color: 'white',
                                padding: '10px 20px',
                                margin: '10px 0',
                                borderRadius: '8px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >   
                            <ImportExport style={{ marginRight: '8px' }} /> {/* Icône pour importation */}
                            استيراد النتائج
                        </button>
                        <button 
                            className="btn btn-left" 
                            onClick={() => handleSectionChange('PageLayout3')}
                            style={{
                                backgroundColor: '#218C91',
                                color: 'white',
                                padding: '10px 20px',
                                margin: '10px 0',
                                borderRadius: '8px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >   
                            <Description style={{ marginRight: '8px', fontSize: '24px' }} /> {/* Icône pour محاضر التوجيه */}
                            محاضر التوجيه  
                        </button>
                    </div>
                    <div className="button-group">
                        <button 
                            className="btn btn-right" 
                            onClick={() => handleSectionChange('PageLayout2')}
                            style={{
                                backgroundColor: '#218C91',
                                color: 'white',
                                padding: '10px 20px',
                                margin: '10px 0',
                                borderRadius: '8px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Build style={{ marginRight: '8px' }} /> {/* Icône pour préparation des données */}
                            تهيء المعطيات
                        </button>

                        <button 
                            className="btn btn-right" 
                            onClick={() => handleSectionChange('StudentSelector')}
                            style={{
                                backgroundColor: '#218C91',
                                color: 'white',
                                padding: '10px 20px',
                                margin: '10px 0',
                                borderRadius: '8px',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Edit style={{ marginRight: '8px' }} /> {/* Icône pour saisir une décision */}
                            مسك القرار
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageLayout;
