import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './context/UserContext';

import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ImportFileExcel from './components/dashboardContent/ImportFileExcel';
import PageLayout from './components/dashboardContent/PageLayout';
import PageLayout2 from './components/dashboardContent/PageLayout2';
import AllFichesTechniques from './components/dashboardContent/AllFichesTechniques';
import FicheTechnique from './components/dashboardContent/FicheTechnique';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleAuth = (status) => {
    setIsAuthenticated(status);
    localStorage.setItem('isAuthenticated', status);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* Routes accessibles sans authentification */}
          {!isAuthenticated ? (
            <>
              <Route path="/login" element={<Login setAuth={handleAuth} />} />
              <Route path="/register" element={<Register setAuth={handleAuth} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/AllFichesTechniques" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/FicheTechnique" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/form" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/Report" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/PageLayout" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/ImportFileExcel" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/OriginalInstitution" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/StudentSelector" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/ContactUs" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/PageLayout2" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/EtablissementAccueil" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/LeSeuil" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/InputNotes" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/DeuxiemeLangueSelonDepartement" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/PageLayout3" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/RepartitionDesElevesOrientes" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/EtudiantsInterieurVille" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/DistributionStudentsOutsideCity" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/MinutesOfDepartmentCouncilDecision" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/MinutesOfDepartmentCouncilDecision1" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="/ResultatsDesConseils" element={<Dashboard handleLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
