import React, { useState , useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import jsPDF from 'jspdf'; 
import '../../StylesCSS/EtudiantsInterieurVille.css';
import 'jspdf-autotable';
import { LegendToggleOutlined } from '@mui/icons-material';
import { API_BASE_URL } from '../dashboardContent/config'; 



const EtudiantsInterieurVille = ({ setActiveSection })  => {


  const { userId } = useUser(); 
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedAnneeScolaire, setSelectedAnneeScolaire] = useState('');
  const [selectedReceptionInstitution, setSelectedReceptionInstitution] = useState("");
  console.log("selectedReceptionInstitution",selectedReceptionInstitution);


  const [selectedDate, setSelectedDate] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [valuePlace, setValuePlace] = useState('');
  

  const [directorate, setDirectorate] = useState('');
  const [town, setTown] = useState('');
  const [institutions1, setInstitutions1] = useState([]);
  const [selectedInstitution1, setSelectedInstitution1] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');

  const [commonCore, setCommonCore] = useState('');
  const [boarding, setBoarding] = useState('');
  const [futureSchool, setFutureSchool] = useState('');
  const [external, setExternal] = useState('');


  const [etablissements, setEtablissements] = useState([]);
  const [selectedEtablissement, setSelectedEtablissement] = useState('');
  console.log("commoncore -->>>",commonCore);
  console.log("id user -->>>",userId);
  



   // Charger les établissements chaque fois que "commonCore" change
   useEffect(() => {
    if (commonCore && userId) {
      axios
        .get(`${API_BASE_URL}/api/etablissements/by-common-core-and-user`, {
          params: { commonCore, userId },
        })
        .then((response) => {
          setEtablissements(response.data);
          setSelectedEtablissement(''); // Réinitialiser la sélection
        })
        .catch((error) => {
          console.error('Erreur lors du chargement des établissements:', error);
          setEtablissements([]);
        });
    }
  }, [commonCore, userId]);




  

  console.log("institution ->",selectedInstitution);
  console.log("selectedCode", selectedCode);
  console.log("selectedDate", selectedDate);


  console.log("institutions1",institutions1);
  console.log("classes",classes);
  console.log("selectedInstitution1",selectedInstitution1);
  console.log("selectedClass",selectedClass);
  console.log("Directorate:", directorate);
  console.log("Town:", town);
  console.log("Common Core:", commonCore);
  console.log("Boarding:", boarding);
  console.log("Future School:", futureSchool);
  console.log("External:", external);

      // Récupérer les institutions
      useEffect(() => {
        axios.get(`${API_BASE_URL}/api/originalinstitution/institutionsOriginal/${userId}`)
            .then(response => {
                console.log("Institutions récupérées:", response.data); // Debugging
                setInstitutions1(response.data);
            })
            .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
    }, [userId]);

      const [selectedInstitution2, setSelectedInstitution2] = useState('');
      const [receptionInstitutions, setReceptionInstitutions] = useState([]);

      useEffect(() => {
        console.log("Institution sélectionnée :", selectedInstitution1);
        if (selectedInstitution1) {
          console.log("selectedInstitution1" , selectedInstitution1);
            axios.get(`${API_BASE_URL}/api/receptioninstitution/by-original/${selectedInstitution1}/user/${userId}`)
                .then(response => {
                    console.log("Réceptions récupérées:", response.data); // Vérifie ce que l'API retourne
                    setReceptionInstitutions(response.data);
                })
                .catch(error => console.error('Erreur lors de la récupération des institutions d’accueil:', error));
        } else {
            setReceptionInstitutions([]); // Réinitialiser si aucune institution originale n'est sélectionnée
        }
    }, [selectedInstitution1, userId]);
    

    // Récupérer les classes quand une institution est sélectionnée
    useEffect(() => {
        if (selectedInstitution1) {
            axios.get(`${API_BASE_URL}/api/excel/classes/${userId}/${selectedInstitution1}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Erreur lors de la récupération des classes:', error));
        }
    }, [selectedInstitution1, userId]);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
        .then(response => setInstitutions(response.data))
        .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
}, [userId]);

  const handleSave = async () => {
    const dataToSave = {
        user: { id: userId }, // Assurez-vous d'envoyer l'objet utilisateur avec son id
        institution: selectedInstitution,    
        code: selectedCode, // Ajout de رمزها
        date: selectedAnneeScolaire, // Ajout de تاريخ انعقاد المجلس
    };

    // Sauvegarde des données
    try {
        const saveResponse = await axios.post(`${API_BASE_URL}/api/deuxieme-langue/save`, dataToSave);
        console.log('Données sauvegardées:', saveResponse.data);
        // Vous pouvez ajouter ici une notification de succès ou rediriger l'utilisateur
    } catch (error) {
        console.error('Erreur lors de la sauvegarde des données:', error);
    }
};

const getNombresRepartitionEtudiants = async (selectedInstitution) => {
  const response = await fetch(`${API_BASE_URL}/api/students/Repartition_des_etudiants?selectedInstitution=${selectedInstitution}`);
  const data = await response.json();
  return data; // retourne un objet { filles: x, garcons: y }
};

// Exemple d'utilisation dans ton composant
getNombresRepartitionEtudiants(selectedInstitution).then(data => {
  console.log('countFillesByEnglish1:', data.countFillesByEnglish1);
  console.log('countFillesBySpain1:', data.countFillesBySpain1);
  console.log("somme",data.sommeFilles1 );
  console.log("data",data);
  // Insérer ces valeurs dans le tableau du PDF
});

const getFilteredStudents = async (selectedInstitution1, selectedReceptionInstitution, commonCore) => {
  try {
    console.log("Selected Institution:", selectedInstitution1);
    console.log("Selected Reception Institution:", selectedReceptionInstitution);
    console.log("Common Core:", commonCore);
    console.log("User ID:", userId);  // Afficher l'userId dans la console pour débogage

    const response = await axios.get(`${API_BASE_URL}/api/students/getStudentsByCommonCoreAndselectedInstitution1AndselectedReceptionInstitution`, {
        params: {
          selectedInstitution1: selectedInstitution1, 
          selectedReceptionInstitution: selectedReceptionInstitution,
          commonCore: commonCore,
          userId: userId  // Ajouter l'userId dans les paramètres
        }
    });

    console.log('Données des étudiants filtrés:', response.data); // Afficher les données dans la console
    return response.data; // Retourner les données des étudiants filtrés
  } catch (error) {
      console.error('Erreur lors de la récupération des étudiants filtrés:', error);
      return [];
  }
};







const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  const handleExportPDF = async () => {

    const students = await getFilteredStudents(selectedInstitution1, selectedReceptionInstitution, commonCore);
    const studentTableData = students.map((student, index) => ({
      decisionConseil: student.decisionConseil,
      decisionConseil1: student.decisionConseil,
      deuxiemeLangueEtrangere : student.deuxiemeLangueEtrangere,
      noteTotal : student.noteTotal,
      numeroEleve: student.numeroEleve,
      nomEtPrenom : student.nomEtPrenom,
      num: index + 1
    }));
    console.log("table students",studentTableData);


    const doc = new jsPDF('p', 'pt', 'a4');

    // Ajouter la police arabe
    doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
    doc.setFont('NotoSansArabic');
    doc.setFontSize(12);

    const img = new Image();
    img.src = '/images/Photo1.PNG';
    await new Promise(resolve => {
      img.onload = resolve;
    });
  
    const imgWidth = 250;
    const imgHeight = (img.height * imgWidth) / img.width;
    doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);
  
    doc.setFont('NotoSansArabic');
  
    doc.setFontSize(11);
    doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
  
    // Ajouter les trois premiers textes avec les nouvelles propriétés
    doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
    doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 30, { align: 'center' });
    doc.text('  مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 45, { align: 'center' });
  
    const pageWidth = doc.internal.pageSize.width;
    const centerX = pageWidth / 2;

    doc.setFontSize(10);
    doc.text('  المؤسسة : ' + selectedInstitution1, doc.internal.pageSize.width - 20, imgHeight + 60, { align: 'right' });


 // Définir les marges
 const leftMargin = 320; // marge gauche en mm
 const rightMargin = 20; // marge droite en mm
 const availableWidth = pageWidth - leftMargin - rightMargin; // Largeur disponible

 // Appliquer NotoSans uniquement sur selectedCode
 doc.setFont('NotoSans');
 const codeX = leftMargin; // Positionner à la marge gauche
 const codeY = imgHeight + 60; // Position verticale
 doc.text(selectedCode+  " : ", codeX, codeY, { align: 'left' }); // Afficher selectedCode

 // Appliquer NotoSansArabic uniquement sur 'رمزها'
 doc.setFont('NotoSansArabic');
 const prefix = ' رمزها  ';

 
 const prefixX = leftMargin+20 + doc.getTextWidth(':',selectedCode); // Positionner après le selectedCode
 const prefixY = codeY; // Même position verticale que selectedCode
 doc.text(prefix, prefixX, prefixY, { align: 'left' });

  

    doc.setFont('NotoSansArabic');
    doc.text('  السنة الدراسية: ' + selectedAnneeScolaire, 120, imgHeight + 60, { align: 'left' });

    doc.text(' جدول  -ج- مكرر  ' , doc.internal.pageSize.width / 2, imgHeight + 75, { align: 'center' });
    // Obtenir la largeur du texte pour calculer la position de la ligne
    const textWidth = doc.getTextWidth('جدول - ج -مكر ر');
    const textXPosition = (doc.internal.pageSize.width / 2) - (textWidth / 2);
    doc.setLineWidth(2);

    // Dessiner une ligne sous le texte
    doc.line(textXPosition, imgHeight + 80, textXPosition + textWidth, imgHeight + 80);
  
    // Texte sous le titre
    doc.setFontSize(10);
    doc.text(' يعبا هذا الجدول لكل التلاميذ الموجهين المنقولين إلى مؤسسة داخل البلدة ', 300, imgHeight + 90, { align: 'center' });
    doc.text('ويعتبر بمثابة شهادة مغادرة جماعية', 300, imgHeight + 103, { align: 'center' });
  
// Définir la taille de la police
doc.setFontSize(10);

// Texte pour les colonnes principales
doc.text('المؤسسة الأصلية', 400, 192); 
doc.text('المؤسسة المستقبلة', 130, 192);

// Ajouter les lignes du tableau (haut et bas du cadre)
doc.setLineWidth(1);
doc.line(40, 175, 550, 175); // ligne supérieure du cadre principal
doc.line(40, 290, 550, 290); // ligne inférieure qui inclut tous les champs (ajuster la position pour inclure les sous-champs)

// Ajouter les lignes verticales du cadre
doc.line(40, 175, 40, 274);  // ligne gauche du cadre (du haut jusqu'en bas)
doc.line(300, 175, 300, 274); // ligne séparant les deux colonnes (du haut jusqu'en bas)
doc.line(550, 175, 550, 274); // ligne droite du cadre (du haut jusqu'en bas)


// Sous-champs pour المؤسسة المستقبلة
doc.text(' : الجدع المشترك', 220, 215); // à gauche
doc.text(`${commonCore}`, 220, 215, { align: 'right' }); // Valeur de commonCore à droite

doc.text(': الداخلية ', 252, 230); // ligne suivante
doc.text(`${boarding}`, 240, 230, { align: 'right' }); // Valeur de boarding à droite

doc.text(': المؤسسة', 246, 245); 
doc.text(`${selectedReceptionInstitution}`, 240,  245, { align: 'right' }); // Valeur de futureSchool à droite

doc.text(': الخارحية', 251, 260); 
doc.text(`${external}`, 240, 260, { align: 'right' }); // Valeur de external à droite


// Sous-champs pour المؤسسة الأصلية
doc.text(' : المديرية', 482, 215);
doc.text(`${directorate}`, 470, 215, { align: 'right' }); // Valeur de directorate à droite

doc.text(' : البلدة ', 492, 230);
doc.text(`${town}`, 480, 230, { align: 'right' }); // Valeur de town à droite

doc.text(' : المؤسسة', 477, 245);
doc.text(`${selectedInstitution1}`, 470, 245, { align: 'right' }); // Valeur de selectedInstitution1 à droite

/* doc.text(' : القسم', 490, 260);
doc.setFont('NotoSans');
doc.text(`${selectedClass}`, 475, 260, { align: 'right' }); */ // Valeur de selectedClass à droite
doc.setFont('NotoSansArabic');



// Ajouter les lignes horizontales supplémentaires pour séparer les sous-champs
doc.line(40, 200, 550, 200); // ligne entre les titres et les sous-champs
doc.line(40, 275, 550, 275); // ligne après le dernier sous-champ



  // Ajout du tableau (comme dans l'exemple précédent)
const tableData = Array.from({ length: 40 }, (_, i) => [
  i + 1, '', '', '', '', '', ''
]);

// Définir les colonnes du tableau
const columns = [
  { header: 'قرار المجلس', dataKey: 'decision' },
  { header: 'الرغبة ', dataKey: 'rank' },
  { header: 'اللغة الأجنبية الثانية', dataKey: 'language' },
  { header: 'معدل\nالنجاح', dataKey: 'avg' },
  { header: 'الرقم بجدول التوجيه', dataKey: 'guidance' },
  { header: 'الاسم الكامل', dataKey: 'name' },
  { header: 'الرقم الترتيبي', dataKey: 'num' }
];

doc.autoTable({
  startY: 280, // Commence à 280px en haut pour la première page
  head: [columns.map(col => col.header)],
  body: studentTableData.map(row => [
    row.decisionConseil,
    row.decisionConseil1,
    row.deuxiemeLangueEtrangere,
    row.noteTotal,
    row.numeroEleve,
    row.nomEtPrenom,
    row.num
  ]),

  styles: {
    font: 'NotoSansArabic',
    halign: 'right',
    fontSize: 10,
  },
  columnStyles: {
    0: { cellWidth: 77 },  // Ajuste la largeur des colonnes pour 'قرار المجلس'
    1: { cellWidth: 77 },  // 'الرتبة'
    2: { cellWidth: 100 }, // 'اللغة الأجنبية الثانية'
    3: { cellWidth: 42 },  // 'معدل النجاح'
    4: { cellWidth: 80 }, // 'الرقم بجدول التوجيه'
    5: { cellWidth: 120 }, // 'الاسم الكامل'
    6: { cellWidth: 70 },  // 'رقم ترتيبي'
  },
  margin: { top: 280, right: 20, left: 20 }, // Marge pour la premتاière page
  didDrawPage: function (data) {
    
    if (data.pageNumber === 1) {
      // Marge pour la première page
      data.settings.margin.top = 21; 
    } else {
      // Marge pour les pages suivantes
      data.settings.margin.top = 10; 
    }

    // Ajuster la marge après la 23ème ligne
    const lastRowIndex = data.cursor.index; // Obtenir l'index de la dernière ligne dessinée
    if (lastRowIndex === 22) { // 22 car les index commencent à 0
      data.settings.margin.top = 50; // Changer la marge selon le besoin
    }
  },
  didParseCell: function (data) {
    // Appliquer la police 'NotoSans' uniquement aux données de la colonne 'numeroEleve'
    if (data.section === 'body' && data.column.index === 4) { // Vérifie que c'est une donnée du body
      data.cell.styles.font = 'NotoSans';
    }
  }
});
const totalPages = doc.internal.getNumberOfPages();
console.log("total number", totalPages);

for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
  doc.setPage(pageNumber);

  // Position de la ligne de bas de page
  const pageHeight = doc.internal.pageSize.height;
  const footerYPosition = pageHeight - 50; // Ajuste la position verticale ici (30 mm du bas)

  // Vérifiez si nous sommes sur la dernière page
  if (pageNumber === totalPages) {
    // Dessiner le texte de bas de page uniquement sur la dernière page
    doc.setFont('NotoSansArabic');
    doc.text( ' حـــرر بــ : ' + valueDate + '  ' +  'في  : ' + valuePlace, pageWidth / 2, footerYPosition, { align: 'center' });
    doc.text(': توقيع رئيس المؤسسة ', 480, footerYPosition + 20);
  }
}
    // Enregistrer le PDF
    doc.save(' جدول ج مكرر.pdf');
  };


  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2, 
        padding: '2rem', 
        backgroundColor: '#f4f6f8', // Couleur de fond douce
        borderRadius: '10px', 
        border: '1px solid #dcdcdc',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '700px',
        margin: 'auto',
        textAlign: 'right',
      }}
    >
      <Typography 
        variant="h5" 
        sx={{ 
          marginBottom: '1rem', 
          fontWeight: '500', 
          color: '#2c3e50',
          textAlign: 'center'
        }}
      >
جدول ( ج ) مكرر                    </Typography>

{/* <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1.5rem' }}>
        <InputLabel
          sx={{
            textAlign: 'right',
            fontSize: '1.1rem',
            marginBottom: '0.5rem',
            color: '#2c3e50',
          }}
        >
          اختر المؤسسة
        </InputLabel>
        <Select
          value={selectedInstitution}
          onChange={(e) => setSelectedInstitution(e.target.value)}
          label="اختر المؤسسة"
          sx={{
            textAlign: 'right',
            direction: 'rtl',
            backgroundColor: '#ffffff',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            },
            '&:focus': {
              borderColor: '#2980b9', // Bleu plus clair lors du focus
            },
          }}
        >
          {institutions.map((institution) => (
            <MenuItem key={institution} value={institution} style={{ textAlign: 'right' }}>
              {institution}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

{/* Input pour رمزها */}
<TextField
        fullWidth
        label="رمزها"
        value={selectedCode}
        onChange={(e) => setSelectedCode(e.target.value)}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />
      <TextField
        fullWidth
        label="السنة الدراسية"
        value={selectedAnneeScolaire}
        onChange={(e) => setSelectedAnneeScolaire(e.target.value)}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />

      
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>

  {/* Champ "في" */}
  <div style={{ flex: 1, textAlign: 'right' }}>
    <label htmlFor="fi" style={{ display: 'block', marginBottom: '0.5rem' }}> : في</label>
    <input 
      type="text" 
      id="fi" 
      value={valuePlace} 
      onChange={(e) => setValuePlace(e.target.value)} 
      style={{
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        textAlign: 'right',
        direction: 'rtl',
      }}
    />
  </div>
   {/* Champ "حرر ب" */}
   <div style={{ flex: 1, marginLeft: '1rem', textAlign: 'right' }}>
    <label htmlFor="harar" style={{ display: 'block', marginBottom: '0.5rem' }} > : حرر ب</label>
    <input 
      type="date" 
      id="harar" 
      value={valueDate} 
      onChange={(e) => setValueDate(e.target.value)} 
      style={{
        width: '100%',
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        textAlign: 'right',
        direction: 'rtl',
      }}
    />
  </div>
</div>



      <div className="container1">
      {/* Div pour الموسسة المستقبلة */}
      <div className="box1 future1">
        <h3> المؤسسة المستقبلة</h3>
        <label htmlFor="common-core">الجدع المشترك</label>
<select
  id="common-core"
  name="common-core"
  value={commonCore}
  onChange={(e) => setCommonCore(e.target.value)}
  style={{ textAlign: 'right' }}
>
  <option value="">أدخل الجدع المشترك</option>
  <option value="الجذع المشترك للآداب والعلوم الإنسانية">الجذع المشترك للآداب والعلوم الإنسانية</option>
                    <option value="الجذع المشترك العلمي">الجذع المشترك العلمي</option>
                    <option value="الجذع المشترك العلمي خيار فرنسية">الجذع المشترك العلمي خيار فرنسية</option>
                    <option value="الجذع المشترك العلمي خيار إنجليزية">الجذع المشترك العلمي خيار إنجليزية</option>
                    <option value="الجذع المشترك العلمي خيار إسبانية">الجذع المشترك العلمي خيار إسبانية</option>
                    <option value="الجذع المشترك التكنولوجي">الجذع المشترك التكنولوجي</option>
                    <option value="الجذع المشترك الخدماتي">الجذع المشترك الخدماتي</option>
                    <option value="الجذع المشترك الصناعي">الجذع المشترك الصناعي</option>
                    <option value="الجذع المشترك العلمي تخصص تربية بدنية">الجذع المشترك العلمي تخصص تربية بدنية</option>
                    <option value="الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية">الجذع المشترك للآداب والعلوم الإنسانية تخصص تربية بدنية</option>
                    <option value="الجذع المشترك للتعليم الأصيل">الجذع المشترك للتعليم الأصيل</option>
                    <option value="الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية">الجذع المشترك للآداب والعلوم الإنسانية للمسالك الدولية للبكالوريا المغربية</option>
</select>


<>
  <label htmlFor="reception-school">: المؤسسة المستقبلة</label>
  <select 
    className="select"
    value={selectedReceptionInstitution} // Assurez-vous que selectedReceptionInstitution contienne le nom
    onChange={(e) => setSelectedReceptionInstitution(e.target.value)} // Mise à jour du nom lors de la sélection
  >
    <option value="" style={{ textAlign: 'right' }}>-- اختر --</option>
    {receptionInstitutions.map(reception => (
      <option 
        key={reception.id} 
        value={reception.receptionInstitutionName} // Utilisation de receptionInstitutionName ici
        style={{ textAlign: 'right' }}
      >
        {reception.receptionInstitutionName} 
      </option>
    ))}
  </select>
</>



{/* Deuxième liste déroulante : المؤسسة */}
{/* <label htmlFor="host-institution">المؤسسة</label>
      <select
        id="host-institution"
        name="host-institution"
        value={selectedEtablissement}
        onChange={(e) => setSelectedEtablissement(e.target.value)}
        style={{ textAlign: 'right' }}
      >
        <option value="">أدخل المؤسسة</option>
        {etablissements.map((etablissement) => (
          <option key={etablissement.id} value={etablissement.hostInstitution}>
            {etablissement.hostInstitution} - {etablissement.town}
          </option>
        ))}
      </select> */}

        <label htmlFor="boarding"> : الداخلية  </label>
        <input
          type="text"
          id="boarding"
          name="boarding"
          placeholder="أدخل الداخلية"
          value={boarding}
          onChange={(e) => setBoarding(e.target.value)}
          style={{ textAlign: 'right' }} 

        />
      {/*   <label htmlFor="future-school"> : المؤسسة </label>
        <input
          type="text"
          id="future-school"
          name="future-school"
          placeholder="أدخل الموسسة"
          value={futureSchool}
          onChange={(e) => setFutureSchool(e.target.value)}
          style={{ textAlign: 'right' }} 

        />     */}    

        <label htmlFor="external"> : الخارجية </label>
        <input
          type="text"
          id="external"
          name="external"
          placeholder="أدخل الخارجية"
          value={external}
          onChange={(e) => setExternal(e.target.value)}
          style={{ textAlign: 'right' }} 

        />      </div>
      {/* Div pour الموسسة الاصلية */}
      <div className="box1 original1">
        <h3> المؤسسة الأصلية</h3>
        <label htmlFor="directorate">: المديرية </label>
        <input
          type="text"
          id="directorate"
          name="directorate"
          placeholder="أدخل المديرية"
          value={directorate}
          onChange={(e) => setDirectorate(e.target.value)}
          style={{ textAlign: 'right' }} 
        />

       {/*  <label htmlFor="town">: البلدة </label>
        <input
          type="text"
          id="town"
          name="town"
          placeholder="أدخل البلدة"
          value={town}
          onChange={(e) => setTown(e.target.value)}
          style={{ textAlign: 'right' }} 

        /> */}
        <label htmlFor="original-school">: المؤسسة الاصلية</label>
<select 
  className="select" 
  onChange={(e) => setSelectedInstitution1(e.target.value)} // Mettre à jour selectedInstitution1
  value={selectedInstitution1} // Utiliser selectedInstitution1 comme valeur
>
  <option value="" style={{ textAlign: 'right' }}>-- اختر --</option>
  {institutions1 && institutions1.length > 0 && institutions1.map(institution => (
    <option 
      key={institution.id} 
      value={institution.name} // Utiliser institution.name ici pour la valeur
    >
      {institution.name} 
    </option>
  ))}
</select>

        {/* <label htmlFor="department">القسم :</label>
        <>
                    <select className="select" onChange={(e) => setSelectedClass(e.target.value)} value={selectedClass}>
                        <option value="" style={{ textAlign: 'right' }} >-- اختر --</option>
                        {classes && classes.length > 0 && classes.map(classe => (
                            <option style={{ textAlign: 'right' }}  key={classe} value={classe}>{classe}</option>
                        ))}
                    </select>
        </> */}
      </div>
    </div>

          {/* Boutons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
       <Button 
         variant="outlined" 
         color="secondary" 
         onClick={() => handleGoBack('PageLayout3')}
         sx={{ 
           borderRadius: '8px', 
           borderColor: '#e74c3c', // Couleur de la bordure rouge
           color: '#e74c3c', // Couleur du texte
           '&:hover': {
             backgroundColor: '#f8d7da', // Couleur au survol
           }
         }}
       >
         رجوع
       </Button>
     {/*   <Button 
         variant="contained" 
         color="primary" 
         onClick={handleSave} 
         sx={{ 
           borderRadius: '8px', 
           backgroundColor: '#27ae60', // Vert doux
           '&:hover': {
             backgroundColor: '#219150', // Couleur au survol
           }
         }}
       >
         تاكيد
       </Button> */}
               <button
  onClick={handleExportPDF}
  className="btn btn-secondary"
  style={{
    width: '120px', // Largeur fixe
    height: '50px', // Hauteur fixe
    padding: '10px 20px', // Marges internes pour ajuster la taille
    fontSize: '13px', // Taille du texte
    borderRadius: '8px', // Coins arrondis
  }}
>
  PDF تصدير إلى
</button>
     </Box>
    </Box>

  );
};

export default EtudiantsInterieurVille;
