import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useUser } from '../../context/UserContext';
import '../../StylesCSS/buttons.css';
import { API_BASE_URL } from '../dashboardContent/config'; 


const Report = () => {
  
  const [showMonthlyReport, setShowMonthlyReport] = useState(false);
  const [showPeriodicReport, setShowPeriodicReport] = useState(false);
  const [selectedYearMonthly, setSelectedYearMonthly] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYearPeriodic, setSelectedYearPeriodic] = useState('');
  const [selectedCycle, setSelectedCycle] = useState('');
  const { userId } = useUser(); // Récupérer l'ID utilisateur du contexte
  const [advisor, setAdvisor] = useState("");
  const [schoolSector, setSchoolSector] = useState("");

  console.log("monthly : ", selectedMonth , "year :" , selectedYearMonthly);
  console.log("cycle : ", selectedCycle , "year :" , selectedYearPeriodic);

  const monthsInArabic = {
    '1': 'يناير',
    '2': 'فبراير',
    '3': 'مارس',
    '4': 'أبريل',
    '5': 'مايو',
    '6': 'يونيو',
    '7': 'يوليوز',
    '8': 'غشت',
    '9': 'شتنبر',
    '10': 'أكتوبر',
    '11': 'نونبر',
    '12': 'دجنبر'
  };

  

  const years = Array.from({ length: 101 }, (_, index) => (2000 + index).toString());
  const months = Array.from({ length: 12 }, (_, index) => (index + 1).toString());
  const cycles = ['الدورة الأولى', 'الدورة التانية'];

  const handleExportPDF1 = async () => {
    try {
      // Effectuer une requête pour récupérer les données depuis l'API
      const response = await fetch(
        `${API_BASE_URL}/api/fiche-technique/pdf/monthly-report?userId=${userId}&year=${selectedYearMonthly}&month=${selectedMonth}`
      );
      const responseData = await response.json();

      // Créer le PDF
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      doc.addFileToVFS('NotoSansArabic-Regular.ttf', '/fonts/NotoSansArabic-Regular.ttf');
      doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');
      // Charger l'image
      const img = new Image();
      img.src = '/images/Photo1.PNG';
      await new Promise(resolve => {
         img.onload = resolve;
       });

      const imgWidth = 100;
      const imgHeight = (img.height * imgWidth) / img.width;
      doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);


      doc.setFont('NotoSansArabic');
      doc.setFontSize(18);
      doc.setTextColor(33, 140, 145);
       // Récupérer le nom du mois en arabe
      const monthNameArabic = monthsInArabic[selectedMonth];
      doc.text(` التقرير الشهري : شهر ${monthNameArabic}`, doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
 

      // Assurer que les données correspondent aux attentes
      const tableData = Object.keys(responseData).map(field => [
        responseData[field]["Institutions"] ? responseData[field]["Institutions"].join(', ') : '', // institutions
        ` عدد المستفيدين :   ${responseData[field]["Nombre beneficiare"] || 0}\n` +
        `عدد الأنشطة: ${responseData[field]["Activity Count"] || 0}\n`+
        `الأنشطة: ${responseData[field]["Custom Activities"] ? responseData[field]["Custom Activities"].join(', ') : ''}\n`,

        field // intervention fields
      ]);

      doc.autoTable({
        startY: imgHeight + 30,
        head: [['المؤسسات', 'الانجازات', 'مجالات التدخل']],
        body: tableData,
        theme: 'grid',
        styles: { font: 'NotoSansArabic', fontSize: 10 },
        headStyles: { fillColor: [33, 140, 145], textColor: [255, 255, 255], halign: 'right'},
        columnStyles: {
          0: { cellWidth: 30, halign: 'right' }, // المؤسسات
          1: { cellWidth: 'auto', halign: 'right' }, // الانجازات
          2: { cellWidth: 'auto', halign: 'right' }  // مجالات التدخل
        },
        didDrawPage: (data) => {
          doc.setTextColor(0, 0, 0);
        }
      });
      
      const pageHeight = doc.internal.pageSize.height;
      const signatureY = pageHeight - 30; // Position Y pour les signatures
    
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
      // Signature à droite
      doc.text(':  توقيع مستشار التوجيه', doc.internal.pageSize.width - 20, signatureY, { align: 'right' });
      // Sauvegarder le PDF
      doc.save('monthly-report.pdf');
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };

  const handleExportPDF2 = async () => {
    try {
      // Effectuer une requête pour récupérer les données depuis l'API
      const response = await fetch(
        `${API_BASE_URL}/api/fiche-technique/pdf/periodic-report?userId=${userId}&year=${selectedYearPeriodic}&cycle=${selectedCycle}`
      );
      const responseData = await response.json();

      // Créer le PDF
      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
      });

      doc.addFileToVFS('NotoSansArabic-Regular.ttf', '/fonts/NotoSansArabic-Regular.ttf');
      doc.addFont('/fonts/NotoSansArabic-Regular.ttf', 'NotoSansArabic', 'normal');

      // Charger l'image
      const img = new Image();
      img.src = '/images/Photo1.PNG';
      await new Promise(resolve => {
           img.onload = resolve;
         });
  
      const imgWidth = 150;
      const imgHeight = (img.height * imgWidth) / img.width;
      doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);

      doc.setFont('NotoSansArabic');
      doc.setFontSize(16);
      doc.setTextColor(0, 0, 0);
      doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
      doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 28, { align: 'center' });
      doc.text('     مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 35, { align: 'center' });

      doc.setFont('NotoSansArabic');
      doc.setFontSize(18);
      doc.setTextColor(33, 140, 145);
      doc.text(` التقرير الدوري : ${selectedCycle} `, doc.internal.pageSize.width / 2,imgHeight+50, { align: 'center' });

      doc.setFont('NotoSansArabic');
      doc.setFontSize(22);
      doc.setTextColor(0, 0, 0); // Couleur noire
      
      const nextYear = Number(selectedYearPeriodic) + 1;
      
      doc.text(`التقرير الدوري لأنشطة الإعلام المدرسي والمهني للموسم الدراسي: ${nextYear}-${selectedYearPeriodic}`, 
               doc.internal.pageSize.width / 2, imgHeight + 65, { align: 'center' });
      
      // Souligner le texte
      const textWidth = doc.getTextWidth(`التقرير الدوري لأنشطة الإعلام المدرسي والمهني للموسم الدراسي: ${nextYear}-${selectedYearPeriodic}`);
      const textX = (doc.internal.pageSize.width / 2) - (textWidth / 2);
      const textY = imgHeight + 67; // Légèrement sous le texte
      
      doc.setDrawColor(0, 0, 0); // Couleur de la ligne (noir)
      doc.line(textX, textY, textX + textWidth, textY); // Dessiner la ligne sous le texte
      

      const startY = imgHeight + 80;
      doc.text(`المستشار في التوجيه : ${advisor}`, doc.internal.pageSize.width - 30, startY, { align: 'right' });
      // Affichage du texte français (secteur scolaire en français)


// Afficher le texte arabe (par exemple "القطاع المدرسي") après le texte français
doc.setFont('NotoSansArabic');
const arabicText = ': القطاع المدرسي';
const arabicTextWidth = doc.getTextWidth(arabicText);  // Largeur du texte arabe

// Afficher le texte français (par exemple "23/24")
doc.setFont('NotoSans');
 // Exemple de texte français
const schoolSectorWidth = doc.getTextWidth(schoolSector);  // Largeur du texte français

// Positionner le texte français à droite
const frenchTextX = doc.internal.pageSize.width - 70 - schoolSectorWidth;  // Position du texte français à droite

// Afficher le texte français (par exemple "23/24")
doc.text(schoolSector, frenchTextX, startY + 20, { align: 'right' });

// Positionner le texte arabe à gauche du texte français
const arabicTextX = 268 ;  // Décalage du texte arabe à gauche du texte français

// Afficher le texte arabe ("القطاع المدرسي")
doc.setFont('NotoSansArabic');
doc.text(arabicText, arabicTextX, startY + 20, { align: 'right' });

      // Ajouter une nouvelle page pour afficher le tableau
      doc.addPage();
      const tableData = Object.keys(responseData).map(field => [
        responseData[field]["Institutions"] ? responseData[field]["Institutions"].join(', ') : '', // institutions
        `عدد المستفيدين: ${responseData[field]["Nombre beneficiare"] || 0}\n` +
        `عدد الأنشطة: ${responseData[field]["Activity Count"] || 0} \n`+
        `الأنشطة: ${responseData[field]["Custom Activities"] ? responseData[field]["Custom Activities"].join(', ') : ''}`, // accomplishments
        field // intervention fields
      ]);

      doc.autoTable({
        startY:  imgHeight - 10,
        head: [['المؤسسات', 'الانجازات', 'مجالات التدخل']],
        body: tableData,
        theme: 'grid',
        styles: { font: 'NotoSansArabic', fontSize: 10 },
        headStyles: { fillColor: [33, 140, 145], textColor: [255, 255, 255] , halign: 'right' },
        columnStyles: {
          0: { cellWidth: 30, halign: 'right' }, // المؤسسات
          1: { cellWidth: 'auto', halign: 'right' }, // الانجازات
          2: { cellWidth: 'auto', halign: 'right' }  // مجالات التدخل
        },
        didDrawPage: (data) => {
          doc.setTextColor(0, 0, 0);
        }
      });
      // Ajouter une nouvelle page pour afficher le tableau
    doc.addPage();
    // Définir la position de départ
    let startYy = imgHeight;
    const marginRight = 10; // Marge pour l'alignement à droite
    
    doc.setFont('NotoSansArabic');
    doc.setFontSize(18);
    
    // Position du texte
    doc.text(": اهم الاكراهات و الصعوبات المرصودة بالقطاع المدرسي", doc.internal.pageSize.width - marginRight, startYy, { align: 'right' });
    
    // Ajouter une ligne sous le texte pour le souligner
    const textWidth2 = doc.getTextWidth(": اهم الاكراهات و الصعوبات المرصودة بالقطاع المدرسي"); // Largeur du texte
    const underlineY2 = startYy + 2; // Position de la ligne légèrement sous le texte
    doc.line(doc.internal.pageSize.width - marginRight - textWidth2, underlineY2, doc.internal.pageSize.width - marginRight, underlineY2);
    
    startYy += 12; // Espacement après le titre souligné
    
// Définir le texte des problèmes
const issues = [
  "غياب الفضاءات خاصة بالتوجيه التربوي بجميع المؤسسات التابعة للقطاع المدرسي الشي ء الذي يؤثر سلبا على مجموعة كبيرة من الأنشطة وعدم ملائمتها في حال توفرها؛",
  "نقص في وسائل العمل: عدم توفر آلة ناسخة خاصة بالتوجيه التربوي، وكذلك عدم توفر الأوراق مما يجعل عملية نسخ المطويات الخاصة بالتلاميذ عملية شبه مستحيلة نظرا للأعداد الكبيرة للتلاميذ؛",
  " .نسبة التأطير المرتفعة: ارتفاع المعدل الإقليمي للتأطير بالمديرية، مما يشكل صعوبة في إنجاز الأنشطة بالشكل المطلوب "
];

doc.setFont('NotoSansArabic');
doc.setFontSize(16);
doc.setTextColor(0, 0, 0);

issues.forEach((issue, index) => {
  const issueNumber = ` - ${index + 1}`; // Numérotation

  // Dessiner le numéro à droite
  doc.text(issueNumber, doc.internal.pageSize.width - marginRight, startYy, { align: 'right' });

  // Dessiner le texte juste après le numéro
  doc.text(issue, doc.internal.pageSize.width - marginRight - 10, startYy, { align: 'right', maxWidth: 320 });

  startYy += 13; // Espacement entre les lignes
});
  // Ajouter un espacement avant "التوصيات المقترحة"
startYy += 15;

// Ajouter le titre "التوصيات المقترحة"
doc.text(" : التوصيات المقترحة  ", doc.internal.pageSize.width - marginRight, startYy, { align: 'right' });

// Ajouter une ligne sous le texte pour le souligner
const textWidth1 = doc.getTextWidth(" : التوصيات المقترحة  "); // Largeur du texte
const underlineY = startYy + 2; // Position de la ligne légèrement sous le texte
doc.line(doc.internal.pageSize.width - marginRight - textWidth1, underlineY, doc.internal.pageSize.width - marginRight, underlineY);
startYy += 10; // Espacement

// Définir le texte des التوصيات
const recommendations = [
  "تتطلب عملية تجويد أنشطة التوجيه المدرسي والمهني التخفيف من عدد المؤسسات المسندة لكل إطار في التوجيه وذلك بخلق قطاع جديد بكل جماعة من جماعات المديرية؛",
  "تمكين أطر التوجيه من عدة معلوماتية حاسوب محمول مرتبط بشبكة الأنترنيت 4 وطابعة؛",
  " . تخصيص فضاءات مناسبة لتنظيم الأنشطة الخاصة بالتوجيه داخل كل مؤسسات القطاع " 
];

// Ajouter les recommandations
doc.setFontSize(16);
doc.setFont('NotoSansArabic', 'normal');

recommendations.forEach((rec, index) => {
  const recNumber = ` - ${index + 1} `; // Numérotation

  // Dessiner le numéro à droite
  doc.text(recNumber, doc.internal.pageSize.width - marginRight, startYy, { align: 'right' });

  // Dessiner le texte juste après le numéro
  doc.text(rec, doc.internal.pageSize.width - marginRight - 10, startYy, { align: 'right', maxWidth: 320});

  startYy += 13; // Espacement entre les lignes
});

    //doc.text(text, 10, imgHeight + 30, { maxWidth: 400 });
      // Définir la position pour les signatures et le texte central
            const pageHeight = doc.internal.pageSize.height;

      const margin = 20; // Marge à partir des bords 
      const signatureY = pageHeight - 30; // Position Y pour les signatures

      const centerTextY = signatureY - 10; // Position Y pour le texte central (ajustez selon besoin)
      //const signatureY = 150; // Y position pour les signatures (et texte central)

// Positions horizontales
const rightSignatureX = doc.internal.pageSize.width - margin; // Signature à droite
const leftSignatureX = margin; // Signature à gauche
const centerTextX = doc.internal.pageSize.width / 2; // Texte central (au milieu de la page)

    
      doc.setFontSize(13);
      doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes
     // Signature à droite
     doc.text(': توقيع مستشار التوجيه', rightSignatureX, signatureY, { align: 'right' });
     // Texte central
     doc.text(': توقيع المدير الإقليمي', centerTextX, signatureY, { align: 'center' }); 
    // Signature à gauche
    doc.text(': توقيع المفتش', leftSignatureX, signatureY, { align: 'left' });

      // Sauvegarder le PDF
      doc.save('periodic-report.pdf');
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
      <h2 style={{ textAlign: 'right' }}>التقرير</h2>

      <div className="button-container">
        <button
          className="btn btn-monthly"
          onClick={() => {
            setShowMonthlyReport(true);
            setShowPeriodicReport(false);
          }}
        >
          التقرير الشهري
        </button>

        <button
          className="btn btn-periodic"
          onClick={() => {
            setShowMonthlyReport(false);
            setShowPeriodicReport(true);
          }}
        >
          التقرير الدوري
        </button>
      </div>

      {showMonthlyReport && (
        <div className="form-group">
          <label htmlFor="yearMonthly" style={{ display: 'block', textAlign: 'right' }}>السنة</label>
          <select
            id="yearMonthly"
            value={selectedYearMonthly}
            onChange={(e) => setSelectedYearMonthly(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر السنة</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <label htmlFor="month" style={{ display: 'block', textAlign: 'right' }}>الشهر</label>
          <select
            id="month"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="form-control"
          >
            <option value="" style={{ display: 'block', textAlign: 'right' }}>اختر الشهر</option>
            {months.map(month => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
          <button className="btn btn-success" onClick={handleExportPDF1}>
           PDF تحميل على شكل
          </button>
        </div>
      )}

      {showPeriodicReport && (
        <div className="form-group">
          <label htmlFor="yearPeriodic" style={{ display: 'block', textAlign: 'right', direction: 'rtl' }}>السنة</label>
          <select
            id="yearPeriodic"
            value={selectedYearPeriodic}
            onChange={(e) => setSelectedYearPeriodic(e.target.value)}
            className="form-control"
            style={{ textAlign: 'right', direction: 'rtl' }}
          >
            <option value="" style={{ display: 'block', textAlign: 'right' ,direction: 'rtl'}} >اختر السنة</option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>

          <label htmlFor="cycle" style={{ display: 'block', textAlign: 'right' }}>الدورة</label>
          <select
            id="cycle"
            value={selectedCycle}
            onChange={(e) => setSelectedCycle(e.target.value)}
            className="form-control"
            style={{ textAlign: 'right', direction: 'rtl' }}
          >
            <option value="" style={{ display: 'block', textAlign: 'right' , direction: 'rtl' }}>اختر الدورة</option>
            {cycles.map(cycle => (
              <option key={cycle} value={cycle}>
                {cycle}
              </option>
            ))}
          </select>
          {/* Nouveau champ : المستشار في التوجيه */}
    <label htmlFor="advisor" style={{ display: 'block', textAlign: 'right' , direction: 'rtl' }}>المستشار في التوجيه</label>
    <input
      id="advisor"
      type="text"
      className="form-control"
      style={{ textAlign: 'right' , direction: 'rtl' }}
      value={advisor}
      onChange={(e) => setAdvisor(e.target.value)}
    />

    {/* Nouveau champ : القطاع المدرسي */}
    <label htmlFor="schoolSector" style={{ display: 'block', textAlign: 'right' , direction: 'rtl' }}>القطاع المدرسي</label>
    <input
      id="schoolSector"
      type="text"
      className="form-control"
      style={{ textAlign: 'right' , direction: 'rtl' }}
      value={schoolSector}
      onChange={(e) => setSchoolSector(e.target.value)}
    />
    <br></br>

          <button className="btn btn-success" onClick={handleExportPDF2}>
          PDF تحميل على شكل
          </button>
        </div>
      )}
    </div>
  );
};

export default Report;
