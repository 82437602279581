import React from 'react';
import '../../StylesCSS/ButtonsLayout.css';
import { School, Home, Input, ArrowBack } from '@mui/icons-material'; // Import des icônes
import { useNavigate } from 'react-router-dom'; // Import de useNavigate

const PageLayout3 = () => {
    const navigate = useNavigate(); // Initialisation de navigate

    const buttonStyle = {
        backgroundColor: '#33B5E6',
        color: 'white',
        padding: '10px 20px',
        margin: '10px 0',
        borderRadius: '8px',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '400px',
    };

    const buttonLeftStyle = {
        ...buttonStyle,
        backgroundColor: '#FF6347', // Couleur spécifique pour le bouton retour
    };

    return (
        <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center' }}>
            <h2 style={{ textAlign: 'right' }}> : المحاضر</h2>
            <div className="buttons-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/RepartitionDesElevesOrientes')}
                    style={buttonStyle}
                >
                    <School style={{ marginRight: '8px' }} />
                    توزيع التلاميد الموجهين حسب الجدع و الجنس و اللغة الاجنبية الثانية
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/EtudiantsInterieurVille')}
                    style={buttonStyle}
                >
                    <Home style={{ marginRight: '8px' }} />
                    جدول ( ج ) مكرر
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/DistributionStudentsOutsideCity')}
                    style={buttonStyle}
                >
                    <Home style={{ marginRight: '8px' }} />
                    جدول ( ج )
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/MinutesOfDepartmentCouncilDecision')}
                    style={buttonStyle}
                >
                    <Input style={{ marginRight: '8px' }} />
                    محضر قرار مجلس القسم الخاص بالتوجيه
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/MinutesOfDepartmentCouncilDecision1')}
                    style={buttonStyle}
                >
                    <Input style={{ marginRight: '8px' }} />
                    محضر تقييم اشغال مجالس الاقسام الخاصة بالتوجيه
                </button>

                <button
                    className="btn btn-right"
                    onClick={() => navigate('/ResultatsDesConseils')}
                    style={buttonStyle}
                >
                    <Input style={{ marginRight: '8px' }} />
                    النتائج العددية لمجالس الاقسام الخاصة بالتوجيه السنة الثالثة اعدادي
                </button>

                <button
                    className="btn btn-left"
                    style={buttonLeftStyle}
                    onClick={() => navigate('/PageLayout')}
                >
                    <ArrowBack style={{ marginRight: '8px' }} />
                    Retour
                </button>
            </div>
        </div>
    );
};

export default PageLayout3;
