import '../../StylesCSS/Sidebar.css'; // Assure-toi d'importer le fichier CSS
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaWpforms, FaSignOutAlt, FaBars } from 'react-icons/fa'; // Import des icônes
import logo from '../../Images/logo.png'; // Import du logo
import { useNavigate } from 'react-router-dom';


const Sidebar = ({ setActiveSection, handleLogout }) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const confirmLogout = () => {
    handleLogout();
    closeModal();
  };

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleSectionChange = (section) => {
    navigate(`/${section}`); // Redirige vers la route correspondante
    if (window.innerWidth <= 768) {
      setSidebarOpen(false); // Ferme le sidebar sur mobile
    }
  };

  /* const handleSectionChange = (section) => {
    setActiveSection(section);
    if (window.innerWidth <= 768) {
      // Close the sidebar on small screens after selecting a section
      setSidebarOpen(false);
    }
  };
 */
  return (
    <>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
      <div className="sidebar-heading">
          <img src={logo} alt="Logo" className="logo" style={{ width: '40px', height: 'auto', marginRight: '10px' , marginLeft: '20px' }} // Style en ligne pour ajuster la taille du logo
 /> 
          Al Bawsala
        </div>        <div className="list-group list-group-flush">
          <button onClick={() => handleSectionChange('form')} className="list-group-item list-group-item-action bg-light">
            <FaUser />  المعلومات
          </button>
          <button onClick={() => handleSectionChange('FicheTechnique')} className="list-group-item list-group-item-action bg-light">
            <FaEnvelope /> البطاقة التقنية  
          </button>
          <button onClick={() => handleSectionChange('AllFichesTechniques')} className="list-group-item list-group-item-action bg-light">
            <FaWpforms /> مجموع البطاقات التقنية
          </button>
          <button onClick={() => handleSectionChange('Report')} className="list-group-item list-group-item-action bg-light">
            <FaWpforms /> التقرير
          </button>
           <button onClick={() => handleSectionChange('PageLayout')} className="list-group-item list-group-item-action bg-light">
            <FaWpforms /> تدبير مجالس الأقســــام 
          </button> 
          <button onClick={() => handleSectionChange('ContactUs')} className="list-group-item list-group-item-action bg-light">
            <FaWpforms />  اتصل بنا
          </button>
          <button onClick={openModal} className="list-group-item list-group-item-action bg-light">
            <FaSignOutAlt /> خروج
          </button>
          
        </div>
      </div>

      <div className="menu-toggle" onClick={toggleSidebar}>
        <FaBars />
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de déconnexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment vous déconnecter ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Non</Button>
          <Button variant="primary" onClick={confirmLogout}>Oui</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;
