import React, { useState , useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel, Box, Typography, Button ,TextField } from '@mui/material';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import jsPDF from 'jspdf'; 
import 'jspdf-autotable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { API_BASE_URL } from '../dashboardContent/config'; 



const RepartitionDesElevesOrientes = ({ setActiveSection })  => {


  const { userId } = useUser(); 
  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  

  console.log("institution ->",selectedInstitution);
  console.log("selectedCode", selectedCode);
  console.log("selectedDate", selectedDate);


  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/excel/institutions/${userId}`)
        .then(response => setInstitutions(response.data))
        .catch(error => console.error('Erreur lors de la récupération des institutions:', error));
}, [userId]);

  const handleSave = async () => {
    const dataToSave = {
        user: { id: userId }, // Assurez-vous d'envoyer l'objet utilisateur avec son id
        institution: selectedInstitution,    
        code: selectedCode, // Ajout de رمزها
        date: selectedDate, // Ajout de تاريخ انعقاد المجلس
    };

    // Sauvegarde des données
    try {
        const saveResponse = await axios.post(`${API_BASE_URL}/api/deuxieme-langue/save`, dataToSave);
        console.log('Données sauvegardées:', saveResponse.data);
        // Vous pouvez ajouter ici une notification de succès ou rediriger l'utilisateur
    } catch (error) {
        console.error('Erreur lors de la sauvegarde des données:', error);
    }
};

const getNombresRepartitionEtudiants = async (selectedInstitution, userId) => { 
  const response = await fetch(
    `${API_BASE_URL}/api/students/Repartition_des_etudiants?selectedInstitution=${selectedInstitution}&userId=${userId}`
  );
  return response.json();
};



// Exemple d'utilisation dans ton composant
getNombresRepartitionEtudiants(selectedInstitution).then(data => {
  console.log('countFillesByEnglish1:', data.countFillesByEnglish1);
  console.log('countFillesBySpain1:', data.countFillesBySpain1);
  console.log("somme",data.sommeFilles1 );
  console.log("data",data);
  // Insérer ces valeurs dans le tableau du PDF
});





const handleGoBack = (section) => {
    setActiveSection(section);
    console.log("Retour");
  };

  const handleExportPDF = async () => {
    const data = await getNombresRepartitionEtudiants(selectedInstitution, userId);
    const doc = new jsPDF();
  
    // Add Arabic font
    doc.addFileToVFS('NotoSansArabic-Regular2.ttf', '/fonts/NotoSansArabic-Regular2.ttf');
    doc.addFont('/fonts/NotoSansArabic-Regular2.ttf', 'NotoSansArabic', 'normal');
  
    // Set font to Arabic
    doc.setFont('NotoSansArabic');
    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);

    // Ajouter une police pour le texte en français
  doc.addFileToVFS('NotoSans-Regular.ttf', '/fonts/NotoSans-Regular.ttf');
  doc.addFont('/fonts/NotoSans-Regular.ttf', 'NotoSans', 'normal');

  // Charger l'image
  const img = new Image();
  img.src = '/images/Photo1.PNG';
  await new Promise(resolve => {
    img.onload = resolve;
  });

  const imgWidth = 100;
  const imgHeight = (img.height * imgWidth) / img.width;
  doc.addImage(img, 'PNG', (doc.internal.pageSize.width - imgWidth) / 2, 10, imgWidth, imgHeight);

  doc.setFont('NotoSansArabic');

  doc.setFontSize(11);
  doc.setTextColor(0, 0, 0); // Couleur noire pour les trois premières lignes

  // Ajouter les trois premiers textes avec les nouvelles propriétés
  doc.text(' الأكاديمية الجهوية:  طنجة - تطوان - الحسيمة', doc.internal.pageSize.width / 2, imgHeight + 15, { align: 'center' });
  doc.text('  مديرية المضيق - الفنيدق   ', doc.internal.pageSize.width / 2, imgHeight + 20, { align: 'center' });
  doc.text('     مصلحة تأطير المؤسسات التعليمية و التوجيه  ', doc.internal.pageSize.width / 2, imgHeight + 25, { align: 'center' });
  
    // Add title
    doc.text('توزيع التلاميذ الموجهين حسب الجذع و الجنس و اللغة الأجنبية الثانية', doc.internal.pageSize.width / 2, imgHeight + 38, { align: 'center' });
    const pageWidth = doc.internal.pageSize.width;
    const centerX = pageWidth / 2;

    doc.setFontSize(12);
    doc.text('المؤسسة : ' + selectedInstitution, doc.internal.pageSize.width - 20, imgHeight + 45, { align: 'right' });


 // Définir les marges
 const leftMargin = 100; // marge gauche en mm
 const rightMargin = 20; // marge droite en mm
 const availableWidth = pageWidth - leftMargin - rightMargin; // Largeur disponible

 // Appliquer NotoSans uniquement sur selectedCode
 doc.setFont('NotoSans');
 const codeX = leftMargin-6; // Positionner à la marge gauche
 const codeY = imgHeight + 45; // Position verticale
 doc.text(selectedCode, codeX, codeY, { align: 'left' }); // Afficher selectedCode

 // Appliquer NotoSansArabic uniquement sur 'رمزها'
 doc.setFont('NotoSansArabic');
 const prefix = ': رمزها  ';
 const prefixX = leftMargin + doc.getTextWidth(selectedCode); // Positionner après le selectedCode
 const prefixY = codeY; // Même position verticale que selectedCode
 doc.text(prefix, prefixX - 5, prefixY, { align: 'left' });
 const body = [];
 data.forEach(cycle => {
  const cyleName = cycle.name;
  let totaleSum =0;
  let totalMaleSum =0
  let totalFemalSum=0 
  cycle.languages.forEach(language => {
    const languageName = language.name;
    const maleCount = language.sex.find(sex => sex.name === "ذكر")?.count || 0;
    const femaleCount = language.sex.find(sex => sex.name === "أنثى")?.count || 0;
    totaleSum+=(maleCount+femaleCount)
    totalMaleSum+=maleCount
    totalFemalSum+=femaleCount
    // Add to body with the common structure, you can adjust rowSpan if needed
    body.push([
      maleCount+femaleCount, // Assuming you want to keep this empty, replace with appropriate value if needed
        maleCount,
        femaleCount,
        { content: languageName, styles: { halign: 'right' } },
        { content: cyleName, styles: { halign: 'right' }, rowSpan: 4 } // Replace with the actual trunk name if necessary
    ]);
})
body.push([
  totaleSum, // Total row
  totalMaleSum, // Replace with actual summation logic if needed
  totalFemalSum, // Replace with actual summation logic if needed
  { content: 'المجموع', styles: { halign: 'right' } }
]);

});

    doc.setFont('NotoSansArabic');
    doc.text('تاريخ انعقاد المجلس : ' + selectedDate, 20, imgHeight + 45, { align: 'left' });
  
    // Create the main table
    doc.autoTable({
      startY: imgHeight + 50,
      head: [
        [
            { content: 'المجموع', styles: { halign: 'center' } },
            { content: 'ذكور', styles: { halign: 'center' } },
            { content: 'إناث', styles: { halign: 'center' } },
            { content: 'اللغة', styles: { halign: 'center' } },
            { content: 'الجذع', styles: { halign: 'center' } },

        ],
      ],
      body: body,
    

      theme: 'grid',
      styles: { font: 'NotoSansArabic', fontSize: 10 },
      headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 36 }, // Première colonne (المجموع) avec largeur de 30
        1: { cellWidth: 20 }, // Deuxième colonne (ذكور) avec largeur de 20
        2: { cellWidth: 20 }, // Troisième colonne (إناث) avec largeur de 20
        3: { cellWidth: 40 }, // Quatrième colonne (اللغة) avec largeur de 40
        4: { cellWidth: 70 }, // Cinquième colonne (الجذع) avec largeur de 70
      },
      tableWidth: 'auto',
      margin: { right: 10 },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
    }); 
    doc.setFont('NotoSansArabic');
    doc.setFontSize(10);
    doc.text(': توقيع رئيس المؤسسة ', 170, imgHeight + 245, { align: 'left' });
    doc.text('  ملحوضة : ترسل نسختين الي المديرية , وتحتفظ المؤسسة الاصلية بنسخة واحدة ', 90, imgHeight + 260, { align: 'left' });

    
    doc.save('توزيع التلاميذ الموجهين حسب الجذع و الجنس و اللغة الأجنبية الثانية.pdf');
  };


  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        gap: 2, 
        padding: '2rem', 
        backgroundColor: '#f4f6f8', // Couleur de fond douce
        borderRadius: '10px', 
        border: '1px solid #dcdcdc',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        margin: 'auto',
        textAlign: 'right',
      }}
    >
      <Typography 
        variant="h5" 
        sx={{ 
          marginBottom: '1rem', 
          fontWeight: '500', 
          color: '#2c3e50',
          textAlign: 'center'
        }}
      >
                    توزيع التلاميد الموجهين حسب الجدع و الجنس و اللغة الاجنبية الثانية
                    </Typography>

      <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1.5rem'}}>
        <InputLabel sx={{ textAlign: 'right', fontSize: '1.1rem', marginBottom: '0.5rem', color: '#2c3e50' }}>
        اختر المؤسسة        
        </InputLabel>
        <Select
          className="select" 
          onChange={(e) => setSelectedInstitution(e.target.value)} 
          value={selectedInstitution}
          label="اختر المؤسسة"
          sx={{ 
            textAlign: 'right', 
            direction: 'rtl',
            backgroundColor: '#ffffff', // Fond du Select
            '&:focus': {
              borderColor: '#2980b9', // Couleur du focus
            },
          }}
        >
          {institutions && institutions.length > 0 && institutions.map(institution => (
                    <MenuItem style={{ textAlign: 'right' }}  key={institution} value={institution}>{institution}</MenuItem>
                ))}
        </Select>
      </FormControl>

{/* Input pour رمزها */}
<TextField
        fullWidth
        label="رمزها"
        value={selectedCode}
        onChange={(e) => setSelectedCode(e.target.value)}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />

      {/* Input pour تاريخ انعقاد المجلس */}
      <TextField
        fullWidth
        label="تاريخ انعقاد المجلس"
        type="date"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ marginBottom: '1rem', textAlign: 'right', direction: 'rtl' }}
      />

     

      {/* Boutons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '1rem' }}>
       
      <Button 
         variant="outlined" 
         color="secondary" 
         onClick={() => handleGoBack('PageLayout3')}
         sx={{ 
            borderRadius: '8px', 
            borderColor: '#e74c3c', // Couleur de la bordure rouge
            color: '#e74c3c', // Couleur du texte
            padding: '8px 16px', // Ajustez les marges internes
            fontSize: '14px', // Ajustez la taille du texte
            '&:hover': {
                 backgroundColor: '#f8d7da', // Couleur au survol
            }
          }}
          startIcon={<ArrowBackIcon />} // Ajoute l'icône au début du texte
          >
           رجوع     
          </Button>

        
        <button
  onClick={handleExportPDF}
  className="btn btn-secondary"
  style={{
    width: '120px', // Largeur fixe
    height: '50px', // Hauteur fixe
    padding: '10px 20px', // Marges internes pour ajuster la taille
    fontSize: '12px', // Taille du texte
    borderRadius: '8px', // Coins arrondis
  }}
>
  PDF تصدير إلى
</button>

        {/*    <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave} 
          sx={{ 
            borderRadius: '8px', 
            backgroundColor: '#27ae60', // Vert doux
            '&:hover': {
              backgroundColor: '#219150', // Couleur au survol
            }
          }}
        >
          تاكيد
        </Button> */}
      </Box>
    </Box>
  );
};

export default RepartitionDesElevesOrientes;
